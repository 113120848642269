<template>
  <div>
    <div v-if="isLoading" class="spinnerstyle">
      <Spinner />
    </div>
    <div class="login-main-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="login-main-sub-container p-0 position-relative">
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 mt-3 d-flex justify-content-center align-items-baseline"
              >
                <!-- <img src="../../assets/newimgs/GREYLOGO.svg" alt="" /> -->
                <img
                  src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
                  style="
                    display: block;
                    visibility: visible;
                    opacity: 1;
                    height: 40px;
                  "
                  alt=""
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="mt-3 testdetails-container"
                style="padding: 10px; height: calc(100vh - 159px)"
              >
                <div
                  class="login-rightcont-headtxt"
                  style="border-bottom: 1.5px solid white"
                >
                  Install Hire3x Proctoring Agent
                </div>
                <b-row class="mt-2" style="height: 88%">
                  <b-col cols="3">
                    <div class="ximac-steps-div">
                      <!-- Step 1 -->
                      <div
                        :class="{
                          'xi-selected': showStep1Content,
                          'xi-unsel pointer': !showStep1Content,
                        }"
                        @click="step1()"
                        class="d-flex p-1"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            :class="{
                              'xi-sel-svg-back': showStep1Content,
                              'xi-unsel-svg-back': !showStep1Content,
                            }"
                            class="d-flex align-items-center justify-content-center"
                          >
                            <img
                              :src="
                                showStep1Content
                                  ? require('../../assets/newui-img/extstepblue.svg')
                                  : require('../../assets/newimgs/extension/greyStep.svg')
                              "
                              alt=""
                            />
                          </div>
                          <div
                            :class="{
                              'xi-sel-text pointer ml-3': showStep1Content,
                              'xi-unsel-text pointer ml-3': !showStep1Content,
                            }"
                          >
                            Step 1
                          </div>
                        </div>
                      </div>
                      <!-- Step 2 -->
                      <div
                        :class="{
                          'xi-selected': showStep2Content,
                          'xi-unsel pointer': !showStep2Content,
                        }"
                        @click="step2()"
                        class="d-flex p-1 mt-2"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            :class="{
                              'xi-sel-svg-back': showStep2Content,
                              'xi-unsel-svg-back': !showStep2Content,
                            }"
                            class="d-flex align-items-center justify-content-center"
                          >
                            <img
                              :src="
                                showStep2Content
                                  ? require('../../assets/newui-img/extstepblue.svg')
                                  : require('../../assets/newimgs/extension/greyStep.svg')
                              "
                              alt=""
                            />
                          </div>
                          <div
                            :class="{
                              'xi-sel-text pointer ml-3': showStep2Content,
                              'xi-unsel-text pointer ml-3': !showStep2Content,
                            }"
                          >
                            Step 2
                          </div>
                        </div>
                      </div>
                      <div
                        :class="{
                          'xi-selected': showStep3Content,
                          'xi-unsel pointer': !showStep3Content,
                        }"
                        @click="step3()"
                        class="d-flex p-1 mt-2"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            :class="{
                              'xi-sel-svg-back': showStep3Content,
                              'xi-unsel-svg-back': !showStep3Content,
                            }"
                            class="d-flex align-items-center justify-content-center"
                          >
                            <img
                              :src="
                                showStep3Content
                                  ? require('../../assets/newui-img/extstepblue.svg')
                                  : require('../../assets/newimgs/extension/greyStep.svg')
                              "
                              alt=""
                            />
                          </div>
                          <div
                            :class="{
                              'xi-sel-text pointer ml-3': showStep3Content,
                              'xi-unsel-text pointer ml-3': !showStep3Content,
                            }"
                          >
                            Step 3
                          </div>
                        </div>
                      </div>
                      <div
                        :class="{
                          'xi-selected': showStep4Content,
                          'xi-unsel pointer': !showStep4Content,
                        }"
                        @click="step4()"
                        class="d-flex p-1 mt-2"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            :class="{
                              'xi-sel-svg-back': showStep4Content,
                              'xi-unsel-svg-back': !showStep4Content,
                            }"
                            class="d-flex align-items-center justify-content-center"
                          >
                            <img
                              :src="
                                showStep4Content
                                  ? require('../../assets/newui-img/extstepblue.svg')
                                  : require('../../assets/newimgs/extension/greyStep.svg')
                              "
                              alt=""
                            />
                          </div>
                          <div
                            :class="{
                              'xi-sel-text pointer ml-3': showStep4Content,
                              'xi-unsel-text pointer ml-3': !showStep4Content,
                            }"
                          >
                            Step 4
                          </div>
                        </div>
                      </div>
                      <div
                        :class="{
                          'xi-selected': showStep5Content,
                          'xi-unsel pointer': !showStep5Content,
                        }"
                        @click="step5()"
                        class="d-flex p-1 mt-2"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            :class="{
                              'xi-sel-svg-back': showStep5Content,
                              'xi-unsel-svg-back': !showStep5Content,
                            }"
                            class="d-flex align-items-center justify-content-center"
                          >
                            <img
                              :src="
                                showStep5Content
                                  ? require('../../assets/newui-img/extstepblue.svg')
                                  : require('../../assets/newimgs/extension/greyStep.svg')
                              "
                              alt=""
                            />
                          </div>
                          <div
                            :class="{
                              'xi-sel-text pointer ml-3': showStep5Content,
                              'xi-unsel-text pointer ml-3': !showStep5Content,
                            }"
                          >
                            Step 5
                          </div>
                        </div>
                      </div>

                      <div class="ext-line-with-text mt-2 w-100">
                        <div class="ext-line w-100"></div>
                        <div class="ext-or-text">OR</div>
                        <div class="ext-line w-100"></div>
                      </div>
                      <div
                        class="d-flex p-1 mt-2"
                        :class="{
                          'xi-selected': showStep6Content,
                          'xi-unsel pointer': !showStep6Content,
                        }"
                        @click="step6()"
                      >
                        <div class="xi-sel-bar"></div>
                        <div class="d-flex align-items-center ml-2">
                          <div
                            class="xi-unsel-svg-back d-flex align-items-center justify-content-center"
                          >
                            <img
                              src="../../assets/newimgs/extension/extensionvideo.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="ml-3"
                            :class="{
                              'xi-sel-text': showStep6Content,
                              'xi-unsel-text': !showStep6Content,
                            }"
                          >
                            Watch video instruction
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="9" class="p-4">
                    <div
                      class="d-flex align-items-center justify-content-between xi-head"
                    >
                      <div class="xi-main-text" v-if="showStep1Content">
                        Step 1
                      </div>
                      <div class="xi-main-text" v-if="showStep2Content">
                        Step 2
                      </div>

                      <div class="xi-main-text" v-if="showStep3Content">
                        Step 3
                      </div>

                      <div class="xi-main-text" v-if="showStep4Content">
                        Step 4
                      </div>

                      <div class="xi-main-text" v-if="showStep5Content">
                        Step 5
                      </div>
                      <div class="xi-main-text" v-if="showStep6Content">
                        Watch video instruction
                      </div>

                      <div class="bluebtns ml-3">
                        <!-- use btn name stop checking while the audio is checked -->
                        <b-button
                          variant="primary"
                          class="pl-4 pr-4"
                          @click="downloadAgent()"
                          >Download Agent</b-button
                        >
                      </div>
                    </div>
                    <div
                      class="xi-image-desc"
                      v-if="showStep1Content"
                      style="height: 80px"
                    >
                      Download and open the application permit the access as
                      follows
                    </div>
                    <div
                      class="xi-image-desc"
                      v-if="showStep2Content"
                      style="height: 80px"
                    >
                      Click on
                      <strong>“System Preferences”</strong>, then navigate to
                      the Security Options section of the System Preferences
                      page. You must also enter your laptop's password.
                    </div>
                    <div
                      class="xi-image-desc"
                      v-if="showStep3Content"
                      style="height: 80px"
                    >
                      After granting access, the user should navigate to the
                      install Simplify CV Proctoring page and click
                      <strong>“Continue”</strong>. After clicking on the
                      continue button, user should navigate to Install Simplify
                      CV Proctoring page and click on
                      <strong>“Install”</strong>.-
                    </div>
                    <div
                      class="xi-image-desc"
                      v-if="showStep4Content"
                      style="height: 80px"
                    >
                      For installing software, you must enter your system
                      password and click on install software.
                    </div>
                    <div
                      class="xi-image-desc"
                      v-if="showStep5Content"
                      style="height: 80px"
                    >
                      For installing software, you must enter your system
                      password and click on install software.
                    </div>

                    <b-row class="mt-4">
                      <b-col cols="10" v-if="showStep1Content">
                        <div
                          class="d-flex align-items-end justify-content-center"
                        >
                          <div class="ximac-image-div">
                            <img
                              class="h-100 w-100"
                              src="../../assets/newimgs/Agent/macStep1.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="10" v-if="showStep2Content">
                        <div
                          class="d-flex align-items-end justify-content-center"
                        >
                          <div class="ximac-image-div">
                            <img
                              class="h-100 w-100"
                              src="../../assets/newimgs/Agent/macStep2.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col v-if="showStep3Content">
                        <div
                          class="d-flex align-items-end justify-content-center"
                        >
                          <div class="ximac-image-div">
                            <img
                              class="h-100 w-100"
                              src="../../assets/newimgs/Agent/macStep3_1.png"
                              alt=""
                            />
                          </div>
                          <div class="ximac-image-div ml-4">
                            <img
                              class="h-100 w-100"
                              src="../../assets/newimgs/Agent/macStep3_2.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col v-if="showStep4Content">
                        <div
                          class="d-flex align-items-end justify-content-center"
                        >
                          <div class="ximac-image-div">
                            <img
                              class="h-100 w-100"
                              src="../../assets/newimgs/Agent/macStep4.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col v-if="showStep5Content">
                        <div
                          class="d-flex align-items-end justify-content-center"
                        >
                          <div class="ximac-image-div">
                            <img
                              class="h-100 w-100"
                              src="../../assets/newimgs/Agent/macStep5.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" v-if="showStep6Content">
                        <div
                          class="d-flex justify-content-center align-items-center"
                          style="height: calc(100vh - 345px)"
                        >
                          <iframe
                            title="Mac Process Video"
                            class="w-100 h-100"
                            :src="'https://www.youtube.com/embed/I9f67Se-Erk'"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </b-col>
                      <b-col cols="2">
                        <div
                          class="d-flex align-items-end justify-content-end h-100"
                        >
                          <div>
                            <img
                              v-if="showStep1Content == true"
                              class="mr-4"
                              src="../../assets/newimgs/extension/disableLeft.svg"
                              alt=""
                            />

                            <img
                              v-else-if="
                                showStep2Content == true ||
                                showStep3Content == true ||
                                showStep4Content == true ||
                                showStep5Content == true
                              "
                              @click="navigateToPrevStep"
                              src="../../assets/newimgs/extension/activeLeft.svg"
                              alt=""
                              class="mr-4 pointer"
                            />
                          </div>
                          <div>
                            <img
                              v-if="
                                showStep1Content == true ||
                                showStep2Content == true ||
                                showStep3Content == true ||
                                showStep4Content == true
                              "
                              @click="navigateToNextStep"
                              class="mr-4 pointer"
                              src="../../assets/newimgs/extension/activeRight.svg"
                              alt=""
                            />
                            <img
                              v-else-if="showStep5Content"
                              class="mr-4"
                              src="../../assets/newimgs/extension/disableRight.svg"
                              alt=""
                            />
                          </div>

                          <!-- ********************** STEP 2 ARROWS ********************** -->
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <hr class="mb-0" />
          <div
            class="p-3 d-flex align-items-center justify-content-between bluebtns"
          >
            <b-button
              variant="outline-primary"
              @click="$bvModal.show('leavetestmodal')"
              >Leave Test</b-button
            >
            <b-button
              v-if="!verifyAgent"
              variant="primary"
              class="pl-4 pr-4"
              @click="verifyFunc()"
              >Verify Agent</b-button
            >
            <div v-if="verifyAgent">
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                v-if="testDetails.config.allowImageAndIdCapture"
                @click="nextSelfiePage()"
                >Continue</b-button
              >
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                v-else-if="testDetails.config.typingTest"
                @click="goToTypingTest()"
                >Continue</b-button
              >
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                v-else-if="testDetails.config.communicationTest"
                @click="goTocommunicationTest()"
                >Continue</b-button
              >
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                v-else
                @click="nextdosanddonts()"
                >Continue</b-button
              >
            </div>
          </div>
        </div>
        <b-modal
          id="leavetestmodal"
          size="md"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop
          :no-close-on-esc="true"
        >
          <div
            class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div
            @click="$bvModal.hide('leavetestmodal')"
            class="modalcloseicon pointer"
          >
            <img
              class="rotate-on-hover"
              src="../../assets/newimgs/closeicon.svg"
              alt=""
            />
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
          >
            <div>
              <img src="../../assets/newui-img/leavetest.svg" alt="" />
            </div>
            <div class="newui-modal-header mt-4">
              Are you sure you want to leave the test ?
            </div>
            <div class="newui-modal-bodytxt mt-3">
              Please note this action cannot be undone
            </div>
            <div class="bluebtns mt-5">
              <b-button
                class="mr-3 pl-4 pr-4"
                variant="outline-primary"
                @click="$bvModal.hide('leavetestmodal')"
                >Cancel</b-button
              >
              <b-button
                variant="primary"
                class="pl-4 pr-4"
                @click="leaveThetest()"
                >Leave Test</b-button
              >
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <b-modal id="extensionmodal" centered hide-footer hide-header size="md">
      <div
        class="newmodalheadings mt-4 d-flex align-items-center justify-content-center"
      >
        Please Install The Agent
      </div>
      <div
        @click="$bvModal.hide('extensionmodal')"
        class="modalcloseicon pointer"
      >
        <img src="../../assets/newimgs/closeicon.svg" alt="" />
      </div>
      <div class="typingtest-completed-txt mt-5 pl-4 pr-4">
        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut fringilla
        magna porta vel auctor. -->
      </div>
      <div class="mt-5 d-flex justify-content-center">
        <img src="../../assets/newimgs/extension/modalSvg.svg" alt="" />
      </div>
      <div class="d-flex justify-content-center mt-5 mb-4">
        <div class="bluebtns ml-3">
          <!-- use btn name stop checking while the audio is checked -->
          <b-button variant="primary" class="pl-4 pr-4" @click="downloadAgent()"
            >Install</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadAgent } from "../../apiFunction";
import agentMacWinLinux from "../../mixins/agentMacWinLinux.js";
import Spinner from "../fixedcomponents/Spinner.vue";

export default {
  name: "SystemCheck",
  mixins: [agentMacWinLinux],
  data() {
    return {
      isLoading: false,
      verifyAgent: false,
      verifyExtension: false,
      showStep1Content: false,
      showStep2Content: false,
      showStep3Content: false,
      showStep4Content: false,
      showStep5Content: false,
      showStep6Content: false,
    };
  },
  props: {
    NavigatemacOS: Boolean,
  },

  components: {
    Spinner,
  },
  created() {
    this.showStep1Content = true;
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
    }),
  },

  methods: {
    shouldShowNextButton() {
      return (
        this.testDetails.config.allowAgent === true &&
        this.testDetails.config.allowExtension === true
      );
    },
    navigateToNextStep() {
      if (this.showStep1Content) {
        this.step2();
      } else if (this.showStep2Content) {
        this.step3();
      } else if (this.showStep3Content) {
        this.step4();
      } else if (this.showStep4Content) {
        this.step5();
      } else if (this.showStep5Content) {
        // Handle navigation or action for Step 5
      }
    },
    navigateToPrevStep() {
      if (this.showStep5Content) {
        this.step4();
      } else if (this.showStep4Content) {
        this.step3();
      } else if (this.showStep3Content) {
        this.step2();
      } else if (this.showStep2Content) {
        this.step1();
      } else if (this.showStep1Content) {
        // Handle navigation or action for Step 5
      }
    },
    step1() {
      this.showStep2Content = false;
      this.showStep3Content = false;
      this.showStep4Content = false;
      this.showStep5Content = false;
      this.showStep1Content = true;
      this.showStep6Content = false;
    },
    step2() {
      this.showStep2Content = true;
      this.showStep3Content = false;
      this.showStep4Content = false;
      this.showStep5Content = false;
      this.showStep1Content = false;
      this.showStep6Content = false;
    },

    step3() {
      this.showStep2Content = false;
      this.showStep3Content = true;
      this.showStep4Content = false;
      this.showStep5Content = false;
      this.showStep1Content = false;
      this.showStep6Content = false;
    },
    step4() {
      this.showStep2Content = false;
      this.showStep3Content = false;
      this.showStep4Content = true;
      this.showStep5Content = false;
      this.showStep1Content = false;
      this.showStep6Content = false;
    },
    step5() {
      this.showStep2Content = false;
      this.showStep3Content = false;
      this.showStep4Content = false;
      this.showStep5Content = true;
      this.showStep1Content = false;
      this.showStep6Content = false;
    },
    step6() {
      this.showStep2Content = false;
      this.showStep3Content = false;
      this.showStep4Content = false;
      this.showStep5Content = false;
      this.showStep1Content = false;
      this.showStep6Content = true;
    },

    async downloadAgent() {
      const res = await downloadAgent("macOS");
      if (res.status == 200) {
        this.downloadAgentBtn(res.data.data[0].downloadLink);
      }
    },
    downloadAgentBtn(item) {
      window.open(item);
    },
  },
};
</script>

<style>
.ximac-steps-div {
  background-color: #ffffff;
  border-radius: 8px;
  height: calc(100vh - 250px);
  overflow: auto;
  padding: 15px;
}

.ximac-sel-bar {
  background-color: #ffffff;
  border-radius: 8px;
  width: 3px;
  height: 45px;
}

.ximac-sel-svg-back {
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #4c78ce;
}

.ximac-refresh {
  color: #4c78ce;
  font-size: 13px;
  font-weight: 600;
  text-decoration-line: underline;
}

.ximac-main-text {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
}

.ximac-head {
  padding-bottom: 15px;
  border-bottom: 1.5px solid white;
}

.ximac-image-desc {
  color: black;
  font-weight: 500;
  font-size: 16px;
}

.ximac-image-div {
  height: calc(100vh - 450px);
}
</style>

<style scoped>
.xi-warningbox-txt {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #333333;
}
.xi-steps-div {
  background-color: #ffffff;
  border-radius: 8px;
  height: calc(100vh - 400px);
  padding: 15px;
}

.xi-selected {
  background-color: #20639b;
  border-radius: 8px;
}

.xi-unsel {
  background: #ffffff;
  border: 1px solid rgba(140, 140, 140, 0.33);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.17);
  border-radius: 8px;
}

.xi-sel-bar {
  background-color: #ffffff;
  border-radius: 8px;
  width: 1px;
  height: 45px;
}

.xi-sel-svg-back {
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #4c78ce;
}

.xi-unsel-svg-back {
  background-color: #eef3f7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.xi-sel-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.xi-unsel-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.xi-alert-div {
  background: #ffffff;
  border: 0.8px solid rgba(140, 140, 140, 0.45);
  box-shadow: 0px 15px 30px rgba(122, 122, 122, 0.2);
  border-radius: 8px;
  padding: 15px;
  height: 125px;
}

.xi-refresh {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #20639b;
}

.xi-main-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.xi-head {
  padding-bottom: 15px;
  /* border-bottom: 1.5px solid white; */
}

.xi-image-desc {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.xi-image-div {
  height: calc(100vh - 400px);
}
</style>
